import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LocationStrategy, PathLocationStrategy } from '@angular/common';

const routes: Routes = [
  /**
   * Thiết lập path và import các module phát triển trong tương lai vào
   */
  {
    path: '',
    loadChildren: () => import('./layouts/mem-layout/mem-layout.module').then(m => m.MemLayoutModule),
  },
  {
    path: '',
    loadChildren: () => import('./layouts/guest-layout/guest-layout.module').then(m => m.GuestLayoutModule),
  },

  // Navigate to Dashboard (default: '' is dashboard)
  { path: '**', redirectTo: '' },
]

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        // preloadingStrategy: PreloadAllModules,//Tự động load toàn bộ các module khác khi run xong các phần chính
        // useHash: true
      },
    )
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
