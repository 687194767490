import { HttpErrorResponse } from "@angular/common/http";

/**
 * Lớp ErrorBase chịu trách nhiệm chuyển đỗi HttpErrorResponse và lấy dữ liệu
 * từ thuộc tính error để dùng xử lý logic nhiệm vụ cho các service gọi api bằng HttpClient.
 */
export class ErrorBase {
  /**
    * Mã lỗi dạng số.
    */
  public code: number;

  /**
   * Tin nhắn lỗi tùy chọn.
   */
  public message?: string;

  /**
   * Mô tả về lỗi.
   * @remarks Cung cấp thông tin bổ sung về lỗi.
   */
  public desc?: string;

  /**
   * Dữ liệu mở rộng (metadata) liên quan đến lỗi.
   * @remarks Điều này có thể là bất kỳ dữ liệu bổ sung nào liên quan đến lỗi.
   */
  public metadata?: any;

  /**
   * Constructor của lớp HttpErrorHandler.
   * Nhận đối tượng HttpErrorResponse làm tham số và gán các thuộc tính tương ứng.
   *
   * @param httpErrorResponse HttpErrorResponse - Đối tượng chứa thông tin lỗi HTTP.
   */
  constructor(httpErrorResponse: HttpErrorResponse) {
    this.code = httpErrorResponse?.error?.code;
    this.message = httpErrorResponse?.error?.message ?? '';
    this.desc = httpErrorResponse?.error?.desc ?? '';
    this.metadata = httpErrorResponse?.error?.metadata ?? undefined;
  }
}
